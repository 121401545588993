import React, { Component } from 'react'
import Map from './components/Map'

class App extends Component {
  render() {
    return (
      <div>
        <Map />
      </div>
    )
  }
}

export default App
